import styled from "styled-components";

// Botón de llamada a la acción (CTA)
export const CTAButton = styled.a`
  display: inline-block;
  background: linear-gradient(90deg, #a3345f, #ee2aa6);
  box-shadow: 0 2px 10px #dd68b2;
  padding: 0.75rem 2rem;
  font-size: 1.2rem;
  color: #fff;
  text-decoration: none;
  border-radius: 50px;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  margin-top: 1rem;
  margin-bottom: 6rem;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }
`;
