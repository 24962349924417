import React, { useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import { Helmet } from "react-helmet-async";
import { CTAButton } from "../../components/ctaButton/ctaButton";

// Estilos globales optimizados
const GlobalStyle = createGlobalStyle`
  body {
    line-height: 1.3;
    margin: 0;
    font-family: 'Roboto', sans-serif;
  }
`;

// Contenedor principal
const Container = styled.div`
  position: relative;
  max-width: 900px;
  margin: 6rem auto 0 auto;
  padding: 2rem 1rem;
  text-align: center;
`;

// Encabezado
const Header = styled.header`
  margin-bottom: 2rem;
`;

const Title = styled.h1`
  font-size: 2rem;
  text-transform: capitalize;
`;

const Section = styled.section`
  margin-bottom: 2rem;
  text-align: left;
`;

const Subtitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 1rem;
`;

const Text = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ListItem = styled.li`
  font-size: 1.1rem;
  margin-bottom: 0.75rem;
  padding-left: 1.5rem;
  position: relative;

  &:before {
    position: absolute;
    left: 0;
    top: 0;
    color: #00c853;
  }
`;

const CtoLandingPage = ({ texts, language }) => {
  const metaTitle = `${texts.seo.title} | Ysolve Soluciones Tecnológicas`;
  const metaDescription = texts.seo.metaDescription;
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Helmet htmlAttributes={{ lang: language }}>
        {/* Meta tags SEO */}
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={texts.seo.keywords} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={texts.seo.url} />

        {/* Open Graph para redes sociales */}
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content="https://ysolve.es/cto-externo.jpg" />
        <meta property="og:url" content={texts.seo.url} />
        <meta name="twitter:card" content="summary_large_image" />

        {/* Breadcrumbs Schema */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Inicio",
                item: texts.seo.baseUrl,
              },
              {
                "@type": "ListItem",
                position: 2,
                name: texts.title,
                item: texts.seo.url,
              },
            ],
          })}
        </script>

        {/* Schema para el servicio */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Service",
            name: texts.title,
            description: metaDescription,
            provider: {
              "@type": "Organization",
              name: "Ysolve",
              url: texts.seo.baseUrl,
            },
          })}
        </script>
      </Helmet>
      <GlobalStyle />
      <Container style={{}}>
        <Header>
          <Title>{texts.title}</Title>
        </Header>

        <Section id="title">
          <Text>{parse(DOMPurify.sanitize(texts.description))}</Text>
        </Section>

        {texts.sections.map((section, idx) => (
          <Section key={idx}>
            <Subtitle>{section.title}</Subtitle>
            {section.items.type === "list" ? (
              <List>
                {section.items.content.map((item, index) => (
                  <ListItem key={index}>
                    {parse(DOMPurify.sanitize(item))}
                  </ListItem>
                ))}
              </List>
            ) : (
              <>
                {section.items.content.map((texts, index) => {
                  const cleanHTML = DOMPurify.sanitize(texts);
                  return <Text key={index}>{parse(cleanHTML)}</Text>;
                })}
              </>
            )}
          </Section>
        ))}

        <CTAButton
          href="mailto:danielburruchaga@ysolve.es"
          title="Contáctanos para más información"
        >
          ¿Hablamos de tu proyecto?
        </CTAButton>
      </Container>
    </>
  );
};

export default CtoLandingPage;
