import styled from "styled-components";

export const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  width: 100%;

  @media (max-width: 768px) {
    gap: 2rem;
    padding: 0 1rem;
  }

  @media (max-width: 480px) {
    gap: 1rem;
    padding: 0 1rem;
  }
`;

export const Paragraph = styled.p`
  font-size: 1rem;

  @media (max-width: 768px) {
    font-size: 0.875rem; /* Reduce el tamaño de fuente en pantallas pequeñas */
  }
`;
