import React from "react";
import { Video } from "../../components/video";
import { Projects, ProjectsContainer } from "../../containers/projects";
import { HomeContainer, ContentWrapper } from "./Home.styles";
import Footer from "../../components/footer/Footer";
import Contact from "../../containers/contact/Contact";
import { ServiciosVerticales } from "../../containers/servicices-verticales/ServiciosVerticales";

function Home(props) {
  return (
    <HomeContainer id="home" style={{ width: "100%", height: "100%" }}>
      <Video language={props.language} />
      <ContentWrapper>
        <ServiciosVerticales language={props.language} />
        <ProjectsContainer>
          <Projects theme={props.theme} language={props.language} />
        </ProjectsContainer>
        <Contact language={props.language} />
      </ContentWrapper>
    </HomeContainer>
  );
}

export default Home;
