import React from "react";
import { Container } from "./Lenguages.styles";

const Lenguages = ({ changeLanguage, theme }) => {
  return (
    <Container>
      <select
        onChange={(e) => changeLanguage(e.target.value)}
        style={{
          padding: "5px 10px",
          borderRadius: "5px",
          border: "1px solid var(--primary-color)",
          backgroundColor: "transparent",
          color: theme.text,
          cursor: "pointer",
          outline: "none",
        }}
      >
        <option
          style={{ backgroundColor: theme.body, color: theme.text }}
          value="es"
        >
          🇪🇸 Español
        </option>
        <option
          style={{ backgroundColor: theme.body, color: theme.text }}
          value="en"
        >
          🇺🇸 English
        </option>
      </select>
    </Container>
  );
};

export default Lenguages;
