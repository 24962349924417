export const translations = {
  en: {
    header: {},
    video: {
      main: "Smart solutions for a connected world.",
      subheader: "We develop your solution tailored to your needs",
      button: "Schedule a meet",
    },
    greeting: {
      main: "We develop your technological solution",
      button: "View projects",
    },
    servicios: {
      primer: {
        title: "Development of closed projects (Projects 360°)",
        subHeader:
          "We create custom software solutions, from conceptualization to final delivery.",
        description:
          "Quality assurance, fixed deadlines, agile methodologies, post-launch support",
      },
      second: {
        title: "Staff Augmentation",
        subHeader:
          "Incorporate our tech talent into your team to scale quickly and stay agile.",
        description: "Certified talent, fast availability, competitive rates",
      },
      tercer: {
        title: "TechLead As a Service",
        subHeader:
          "We provide the technological leadership your company needs to grow and scale without complications.",
        description:
          "We provide the technological leadership your company needs to grow and scale without complications.",
      },
      title: "Services",
      button: "View more...",
    },
    contact: {
      title: "Contact",
      left: {
        header: "Do you have a project in mind?",
        description:
          "You can send us an email using this form or call us by clicking the button below:",
        button: "Call us",
      },
      form: {
        nombre: `What's your name?`,
        email: "Your email address",
        telefono: "Your phone number",
        empresa: "Your company",
        help: "How can we help you?",
        policity: "I have read and accept the Privacy Policy",
        button: "Submit",
      },
    },
    projects: {
      platformsSection: {
        title: "Web Platforms",
        descriptions: [
          "We develop high-level digital platforms specialized in products, services, and content that facilitate the relationship between their intended users.",
          "We perform custom design and programming to offer your company or startup a unique digital product in the market.",
        ],
        services: [
          "Marketplace",
          "Audio / Video Management",
          "Social Network",
          "Bookings",
          "Ads",
          "Educational",
          "Health & Wellness",
          "Services",
        ],
        buttonText: "VIEW DETAILS",
      },
      solutionsSection: {
        title: "Digital Solutions",
        descriptions: [
          "We create innovative technological solutions that transform the way companies interact with their users and manage their processes.",
          "Our focus is on developing customized tools that drive your business growth and efficiency.",
        ],
        services: [
          "E-commerce",
          "Streaming",
          "Communities",
          "Booking",
          "Marketing",
          "E-learning",
          "B2B",
        ],
        buttonText: "EXPLORE MORE",
      },
      innovationSection: {
        title: "Technological Innovation",
        descriptions: [
          "We implement the latest technologies and methodologies to create innovative solutions that make a difference in the digital market.",
          "Our specialized team works at the forefront of web development to deliver unique and effective experiences.",
        ],
        services: [
          "AI Solutions",
          "Blockchain",
          "IoT",
          "Cloud",
          "Big Data",
          "API Rest",
          "Mobile First",
          "DevOps",
          "Multiplatform",
        ],
        buttonText: "DISCOVER",
      },
      multiplatformSection: {
        title: "Connected Multiplatforms",
        descriptions: [
          "We develop unified digital solutions that operate on web, mobile apps, and embedded systems like automobiles.",
          "Our robust architecture ensures efficient integration between frontend, backend, and databases, optimizing performance and scalability.",
        ],
        services: [
          "Web Development",
          "Mobile Applications",
          "Automotive Systems",
          "Scalable Backend",
          "Distributed Databases",
          "Cloud Computing",
          "Microservices",
          "API Rest & GraphQL",
          "Optimized UX/UI",
        ],
        buttonText: "EXPLORE",
      },
    },
    footer: {
      title: "About Ysolve",
      aboutus: `We are experts in adapting to your project's needs, offering IoT device and firmware development, Full Stack management web platforms, and cross-platform mobile and desktop applications. We provide customized solutions for various sectors, from agronomic sensorization to industrial machinery control, specializing in industrial communication protocols and wireless technologies.`,
    },
  },
  es: {
    header: {},
    video: {
      main: "Soluciones inteligentes para un mundo conectado.",
      subheader: "Desarrollamos tu solución a tu medida",
      button: "Agenda un meet",
    },
    greeting: {
      main: "desarrollamos tú solución tecnológica",
      button: "Ver proyectos",
    },
    servicios: {
      primer: {
        title: "Desarrollo de proyectos cerrados",
        subHeader:
          "Creamos soluciones de software a la medida, desde la conceptualización hasta la entrega final.",
        description:
          "Garantía de calidad, plazos cerrados, metodologías ágiles, soporte post-lanzamiento",
      },
      second: {
        title: "Staff Augmentation",
        subHeader:
          "Incorpora a nuestro talento tecnológico a tu equipo, para escalar rápido y mantener la agilidad.",
        description:
          "Talento certificado, rápida disponibilidad, tarifas competitivas",
      },
      tercer: {
        title: "TechLead As a Service",
        subHeader:
          "Ofrecemos el liderazgo tecnológico que tu empresa necesita para crecer y escalar sin complicaciones.",
        description:
          "Ofrecemos el liderazgo tecnológico que tu empresa necesita para crecer y escalar sin complicaciones.",
      },
      title: "Nuestros Servicios",
      button: "¿Cómo trabajamos?",
    },
    contact: {
      title: "Contacto",
      left: {
        header: "¿Tienes un proyecto entre manos?",
        description:
          "Estaremos encantados de ayudarte. Puedes mandarnos un email utilizando este formulario o puedes llamarnos haciendo click en el siguiente botón:",
        button: "LLÁMANOS",
      },
      form: {
        nombre: "¿Cómo te llamas?",
        email: "Tu correo electrónico",
        telefono: "Tu teléfono",
        empresa: "Tu empresa",
        help: "¿En qué podemos ayudarte?",
        policity: "He leído y acepto la Política de Privacidad",
        button: "Enviar",
      },
    },
    projects: {
      platformsSection: {
        title: "Plataformas web",
        descriptions: [
          "Desarrollamos plataformas digitales de alto nivel especializadas en productos, servicios y contenidos que facilitan la relación entre los usuarios a los que están destinadas.",
          "Realizamos diseño y programación a medida para ofrecer a tu empresa o startup un producto digital único en el mercado.",
        ],
        services: [
          "Marketplace",
          "Gestion Audio / Video",
          "Red social",
          "Reservas",
          "Anuncios",
          "Educativas",
          "Bienestar y salud",
          "Servicios",
        ],
        buttonText: "VER DETALLES",
      },
      solutionsSection: {
        title: "Soluciones digitales",
        descriptions: [
          "Creamos soluciones tecnológicas innovadoras que transforman la manera en que las empresas interactúan con sus usuarios y gestionan sus procesos.",
          "Nuestro enfoque se centra en desarrollar herramientas personalizadas que impulsan el crecimiento y la eficiencia de tu negocio.",
        ],
        services: [
          "E-commerce",
          "Streaming",
          "Comunidades",
          "Booking",
          "Marketing",
          "E-learning",
          "B2B",
        ],
        buttonText: "EXPLORAR MÁS",
      },
      innovationSection: {
        title: "Innovación tecnológica",
        descriptions: [
          "Implementamos las últimas tecnologías y metodologías para crear soluciones innovadoras que marcan la diferencia en el mercado digital.",
          "Nuestro equipo especializado trabaja en la vanguardia del desarrollo web para ofrecer experiencias únicas y efectivas.",
        ],
        services: [
          "AI Solutions",
          "Blockchain",
          "IoT",
          "Cloud",
          "Big Data",
          "API Rest",
          "Mobile First",
          "DevOps",
          "Multiplataforma",
        ],
        buttonText: "DESCUBRIR",
      },
      multiplatformSection: {
        title: "Multiplataformas Conectadas",
        descriptions: [
          "Desarrollamos soluciones digitales unificadas que operan en web, apps móviles y sistemas embebidos como automóviles.",
          "Nuestra arquitectura robusta garantiza una integración eficiente entre frontend, backend y bases de datos, optimizando el rendimiento y la escalabilidad.",
        ],
        services: [
          "Desarrollo Web",
          "Aplicaciones Móviles",
          "Sistemas para Automóviles",
          "Backend Escalable",
          "Bases de Datos Distribuidas",
          "Cloud Computing",
          "Microservicios",
          "API Rest & GraphQL",
          "UX/UI Optimizado",
        ],
        buttonText: "EXPLORAR",
      },
    },
    footer: {
      title: "Sobre Ysolve",
      aboutus: `Somos expertos en adaptarnos a las necesidades de tu proyecto,
      ofreciendo desarrollo de dispositivos IoT y firmware, plataformas web
      de gestión Full Stack, aplicaciones móviles y de escritorio
      multiplataforma. Ofrecemos soluciones personalizadas para diversos
      sectores, desde la sensorización agrónoma hasta el control de
      maquinaria industrial, y nos especializamos en protocolos de
      comunicación industrial y tecnologías inalámbricas.`,
    },
  },
};
