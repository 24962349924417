export const staffAugmentationLandingText = {
  seo: {
    title:
      "Staff Augmentation: El talento que necesitas, justo cuando lo necesitas",
    metaDescription:
      "¿Tu equipo de desarrollo está al límite? Descubre cómo Staff Augmentation te ofrece agilidad, integración instantánea y resultados sin los costos de contratación tradicionales.",
    keywords:
      "Staff Augmentation, talento IT, desarrollo de software, outsourcing, equipos ágiles, integración, tecnología",
    openGraph: {
      title:
        "Staff Augmentation: El talento que necesitas, justo cuando lo necesitas",
      description:
        "Refuerza tu equipo con especialistas sin la burocracia de la contratación tradicional.",
      image: "",
    },
    breadcrumb: {},
    schema: {},
    url: "https://ysolve.es/staff-augmentation",
    baseUrl: "https://ysolve.es",
  },

  title:
    "🚀 Staff Augmentation: El talento que necesitas, justo cuando lo necesitas",
  description: `
      ¿Tu equipo de desarrollo está al límite? Proyectos retrasados, sobrecarga de trabajo y la falta de especialistas pueden detener tu crecimiento. 
      Con nuestro servicio de Staff Augmentation, integra el talento que necesitas de forma ágil y sin los costos fijos de la contratación tradicional.
    `,
  sections: [
    {
      title: "🚨 ¿Tu equipo de desarrollo está al límite?",
      items: {
        type: "text",
        content: [
          "Proyectos retrasados, sobrecarga de trabajo y falta de especialistas pueden frenar tu crecimiento. La contratación tradicional es costosa y lenta, y no siempre encuentras el talento adecuado.",
        ],
      },
    },
    {
      title: "💡 ¿Por qué elegir Staff Augmentation?",
      items: {
        type: "list",
        content: [
          "🚀 Agilidad sin límites: Refuerza tu equipo con desarrolladores, arquitectos de software e ingenieros IoT altamente capacitados.",
          "💰 Menos costos, más resultados: Paga solo por el talento que realmente necesitas, con tarifas competitivas y sin costos ocultos.",
          "🤝 Integración instantánea: Colabora sin fricción desde el primer día, adaptándonos a tu metodología, herramientas y cultura.",
          "⏳ Reduce el riesgo y acelera el éxito: Evita retrasos y problemas por falta de recursos, cumpliendo plazos sin comprometer la calidad.",
        ],
      },
    },
    {
      title: "🔗 ¡Impulsa tu crecimiento!",
      items: {
        type: "text",
        content: [
          "No dejes que la falta de talento detenga tu crecimiento. Contáctanos hoy mismo para descubrir cómo podemos reforzar tu equipo y llevar tus proyectos al siguiente nivel.",
        ],
      },
    },
  ],
};
