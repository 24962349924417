import styled from "styled-components";

export const ContactContainer = styled.div`
  padding: 1rem 2rem;
  background-color: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.text};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4rem;

  @media (max-width: 768px) {
    padding: 0rem 0rem;
    flex-direction: column;
    align-items: stretch;
  }
`;
export const ContactSection = styled.div`
  padding: 0rem 2rem;
  background-color: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.text};
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const Title = styled.h3`
  font-size: 50px;
  letter-spacing: 1px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 4px;
    height: auto;
  }

  @media (max-width: 480px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const MainTitle = styled.h1`
  text-transform: uppercase;
  font-size: 56px;
  font-weight: 1;
  margin-bottom: 1.5rem;
  color: ${({ theme }) => theme.text};

  /* Arreglar el solapamiento */
  letter-spacing: 2px; /* Aumentar el espacio entre letras */
  line-height: 1.2; /* Asegurar espacio vertical adecuado */
  word-spacing: 4px;
  / @media (max-width: 768px) {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 4px;
    height: auto;
  }

  @media (max-width: 480px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.secondaryText};
  margin-bottom: 2rem;
  line-height: 1.6;
`;

export const CallButton = styled.a`
  display: inline-block;
  padding: 1rem 2rem;
  background: linear-gradient(135deg, #ff416c 0%, #ff4b2b 100%);
  color: white;
  text-decoration: none;
  border-radius: 50px;
  font-weight: 600;
  transition: transform 0.3s ease;
  display: flex;
  gap: 1rem;
  justify-content: center;
  // width: fit-content;
  margin-bottom: 1rem;
  &:hover {
    transform: translateY(-2px);
  }
`;

export const WhatsappButton = styled.a`
  display: inline-block;
  padding: 1rem 2rem;
  background: linear-gradient(135deg, #25d366 0%, #128c7e 100%);
  color: white;
  text-decoration: none;
  border-radius: 50px;
  font-weight: 600;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  gap: 1rem;
  // width: fit-content;
  justify-content: center;
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  }
`;
export const ContactForm = styled.form`
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const FormRow = styled.div`
  display: flex;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Input = styled.input`
  width: 100%;
  padding: 1rem;
  border: 2px solid ${({ theme }) => theme.primary};
  border-radius: 8px;
  background-color: transparent;
  color: ${({ theme }) => theme.text};
  font-size: 1rem;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.primary};
  }

  &::placeholder {
    color: ${({ theme }) => theme.text}88;
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  padding: 1rem;
  border: 2px solid ${({ theme }) => theme.primary};
  border-radius: 8px;
  background-color: transparent;
  color: ${({ theme }) => theme.text};
  font-size: 1rem;
  min-height: 150px;
  resize: vertical;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.primary};
  }

  &::placeholder {
    color: ${({ theme }) => theme.text}88;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const Checkbox = styled.input`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

export const SubmitButton = styled.button`
  padding: 1rem 2rem;
  background: linear-gradient(135deg, #ff416c 0%, #ff4b2b 100%);
  text-decoration: none;

  color: #fff;
  border: none;
  border-radius: 50px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.3s ease;
  align-self: flex-end;

  &:hover {
    background-color: ${({ theme }) => theme.primaryDark || theme.primary};
    transform: translateY(-2px);
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
    transform: none;
  }
`;
