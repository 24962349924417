export { Projects } from "./Projects";

export const ProjectsContainer = ({ children }) => (
  <div
    id="projects"
    style={{
      width: "100%",
      height: "100vh",
      overflowX: "hidden",
      position: "relative",
    }}
  >
    {children}
  </div>
);
