import React, { useEffect, useState } from "react";
import { Route, Switch, HashRouter } from "react-router-dom";
import Home from "../pages/home/HomeComponent";
import ServiceDetail from "./ServiceDetail/ServiceDetail";
import Header from "../components/header/Header";
import detectLanguage from "../utils/languaje";
import LandingPage from "../pages/landing/landing";
import { ctoLandingText } from "../data/landing/cto-landing/cto-landing";
import { closedProjectsText } from "../data/landing/closed-project/closed-projects";
import Footer from "../components/footer/Footer";
import { staffAugmentationLandingText } from "../data/landing/staff-augmentation/staff-augmentation";

const Main = (propss) => {
  const [language, setLanguage] = useState(detectLanguage());
  const changeLanguage = (lang) => setLanguage(lang);

  useEffect(() => changeLanguage(language), []);

  return (
    <HashRouter basename="/">
      <Header
        theme={propss.theme}
        setTheme={propss.setTheme}
        changeLanguage={changeLanguage}
      />
      <Switch>
        <Route
          path="/"
          exact
          render={(props) => (
            <Home
              {...props}
              theme={propss.theme}
              setTheme={propss.setTheme}
              language={language}
            />
          )}
        />
        <Route
          path="/home"
          render={(props) => (
            <Home {...props} theme={propss.theme} setTheme={propss.setTheme} />
          )}
        />
        <Route
          path="/service/:id"
          render={(props) => (
            <ServiceDetail
              {...props}
              theme={propss.theme}
              setTheme={propss.setTheme}
            />
          )}
        />

        <Route
          path="/landing/closed-project"
          render={(props) => (
            <LandingPage texts={closedProjectsText} language={language} />
          )}
        />
        <Route
          path="/landing/cto-external"
          render={(props) => (
            <LandingPage texts={ctoLandingText} language={language} />
          )}
        />
        <Route
          path="/landing/staff-augmentation"
          render={(props) => (
            <LandingPage
              texts={staffAugmentationLandingText}
              language={language}
            />
          )}
        />
      </Switch>
      <Footer language={language} />
    </HashRouter>
  );
};

export default Main;
