import React, { useState, useEffect, useRef } from "react";
import { ProjectSection } from "./ProjectSection";
import "./Projects.css";
import { getSections } from "./sections";

const Projects = ({ theme, language }) => {
  const [currentSection, setCurrentSection] = useState(0);
  const containerRef = useRef(null);
  const autoScrollRef = useRef(null);
  const sections = getSections(language);

  const startAutoScroll = () => {
    if (autoScrollRef.current) return;

    autoScrollRef.current = setInterval(() => {
      setCurrentSection((prev) => {
        const next = prev === 2 ? 0 : prev + 1;
        const container = containerRef.current;
        if (container) {
          container.scrollTo({
            left: next * window.innerWidth,
            behavior: "smooth",
          });
        }
        return next;
      });
    }, 12000);
  };

  const stopAutoScroll = () => {
    if (autoScrollRef.current) {
      clearInterval(autoScrollRef.current);
      autoScrollRef.current = null;
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    startAutoScroll();

    const handleScroll = () => {
      const scrollLeft = container.scrollLeft;
      const width = container.clientWidth;
      const newSection = Math.round(scrollLeft / width);

      if (newSection !== currentSection) {
        setCurrentSection(newSection);
      }
    };

    const handleVisibilityChange = () => {
      if (document.hidden) {
        stopAutoScroll();
      } else {
        startAutoScroll();
      }
    };

    container.addEventListener("scroll", handleScroll);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      stopAutoScroll();
      container.removeEventListener("scroll", handleScroll);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [currentSection]);

  return (
    <div className="projects-container" ref={containerRef}>
      <div className="sections-wrapper">
        {sections.map((section, index) => (
          <ProjectSection
            key={index}
            {...section}
            isActive={currentSection === index}
            theme={theme}
          />
        ))}
      </div>
    </div>
  );
};

export { Projects };
