import React, { useState } from "react";
import {
  CallButton,
  WhatsappButton,
  Checkbox,
  CheckboxContainer,
  ContactContainer,
  ContactForm,
  ContactSection,
  Description,
  FormRow,
  Input,
  MainTitle,
  SubmitButton,
  TextArea,
  Title,
} from "./Contact.styles";
import { translations } from "../../utils/translations";

const Contact = ({ language }) => {
  const { contact } = translations[language];
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
    message: "",
    privacy: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };

  return (
    <ContactContainer id="contact">
      <ContactSection>
        {/*  <Title>{contact.title}</Title>  */}
        <MainTitle>{contact.left.header}</MainTitle>
        <Description>{contact.left.description}</Description>
        <CallButton href="tel:627669080">
          <i class="fa fa-phone"></i> {contact.left.button}
        </CallButton>
        <WhatsappButton href="https://wa.me/627669080" target={"_blank"}>
          <i class="fab fa-whatsapp"></i>
          WHATSAPP
        </WhatsappButton>
      </ContactSection>
      <ContactSection>
        <ContactForm onSubmit={handleSubmit}>
          <FormRow>
            <Input
              type="text"
              name="name"
              placeholder={contact.form.nombre}
              value={formData.name}
              onChange={handleChange}
              required
            />
          </FormRow>
          <FormRow>
            <Input
              type="email"
              name="email"
              placeholder={contact.form.email}
              value={formData.email}
              onChange={handleChange}
              required
            />
            <Input
              type="tel"
              name="phone"
              placeholder={contact.form.telefono}
              value={formData.phone}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <Input
              type="text"
              name="company"
              placeholder={contact.form.empresa}
              value={formData.company}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <TextArea
              name="message"
              placeholder={contact.form.help}
              value={formData.message}
              onChange={handleChange}
              required
            />
          </FormRow>
          <CheckboxContainer>
            <Checkbox
              type="checkbox"
              name="privacy"
              checked={formData.privacy}
              onChange={handleChange}
              required
            />
            <span>{contact.form.policity}</span>
          </CheckboxContainer>
          <SubmitButton type="submit" disabled={!formData.privacy}>
            {contact.form.button}
          </SubmitButton>
        </ContactForm>
      </ContactSection>
    </ContactContainer>
  );
};

export default Contact;
