export const ctoLandingText = {
  seo: {
    title: "CTO Externo: Liderazgo Tecnológico Sin Costos Fijos",
    metaDescription: "",
    keywords:
      "CTO Externo, liderazgo tecnológico, dirección tecnológica, sin costos fijos, Ysolve, desarrollo de software",
    openGraph: {
      title: "",
      description: "",
      image: "",
    },
    breadcrumb: {},
    schema: {},
    url: "https://ysolve.es/cto-externo",
    baseUrl: "https://ysolve.es",
  },

  title: "🚀 CTO Externo: Liderazgo Tecnológico Sin Costos Fijos",
  description: `
        ¿Necesitas un Director de Tecnología (CTO) con experiencia pero sin
        los costos de una contratación permanente? Con nuestro servicio de
        CTO Externo, te ofrecemos el liderazgo tecnológico que tu empresa
        necesita para crecer y escalar sin complicaciones.`,
  sections: [
    {
      title: "🏆 ¿Por qué contratar un CTO Externo?",
      items: {
        type: "list",
        content: [
          "✅ Estrategia tecnológica alineada con tu negocio.",
          "✅ Optimización y escalabilidad de tu infraestructura IT.",
          "✅ Gestión y liderazgo de equipos de desarrollo.",
          "✅ Selección de las mejores tecnologías y herramientas.",
          "✅ Mayor seguridad y eficiencia en tus procesos tecnológicos.",
        ],
      },
    },
    {
      title: "📈 Ideal para:",
      items: {
        type: "list",
        content: [
          "✅ Startups y empresas en crecimiento que necesitan expertise técnica sin costos fijos elevados.",
          "✅ Negocios sin un equipo técnico interno que requieren asesoría estratégica.",
          "✅ Empresas que buscan escalar su tecnología de manera rápida y efectiva.",
        ],
      },
    },
    {
      title: "🔥 ¡Transforma tu tecnología en una ventaja competitiva!",
      items: {
        type: "text",
        content: [
          "Con nuestra experiencia en desarrollo, IoT, infraestructura y  software, te ayudamos a llevar tu negocio al siguiente nivel sin la necesidad de contratar un CTO a tiempo completo.",
          "📩 <strong>Contáctanos hoy</strong> y descubre cómo podemos impulsar tu empresa con un CTO Externo.",
        ],
      },
    },
  ],
};
