import { useHistory } from "react-router-dom";
import "./ServicesCard.css";

const ServicesCard = ({
  backImage,
  title,
  description,
  benefices,
  id,
  buttonTxt,
}) => {
  const history = useHistory();

  const handleNavigation = () => {
    history.push(`/service/${id}`);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="container">
        <div
          className="card"
          style={{
            backgroundImage: `url(${backImage})`,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            backgroundBlendMode: "overlay",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          onClick={handleNavigation}
        >
          <div className="box">
            <div className="content">
              <h3 style={{    textTransform: 'uppercase', lineHeight: 1}}>{title}</h3>
              <p>{description}</p>
              <p>{benefices}</p>
            </div>
          </div>
        </div>
        <div
          className="div"
          style={{
            width: "200px",
          }}
        >
          <button
            style={{ background: "rgb(0,0,0,0.4)" }}
            className="button-services"
            onClick={handleNavigation}
          >
            {buttonTxt}
          </button>
        </div>
      </div>
    </div>
  );
};

export { ServicesCard };
