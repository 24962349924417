import React from "react";
import "./Projects.css";

export const ProjectSection = ({
  title,
  descriptions,
  services,
  buttonText,
  isActive,
  image,
  theme,
}) => {
  return (
    <div className={`servicios-verticales-wrapper ${isActive ? "active" : ""}`}>
      <div className="content-section">
        <h1 className="title"  style={{ textTransform: "uppercase",fontWeight:1 }}>
          {title}
        </h1>
        {descriptions.map((desc, index) => (
          <p key={index} className="description">
            {desc}
          </p>
        ))}
        <div className="tags-container">
          {services.map((service, index) => (
            <span
              key={index}
              className="tag"
              style={{
                boxShadow:
                  theme.name !== "dark"
                    ? "0 2px 8px rgba(0, 0, 0, 0.1)"
                    : "0 2px 8px rgba(255, 255, 255, 0.1)",
              }}
            >
              {service}
            </span>
          ))}
        </div>
        <button
          className="button"
          style={{
            boxShadow:
              theme.name !== "dark"
                ? "0 2px 8px rgba(0, 0, 0, 0.1)"
                : "0 2px 8px rgba(255, 255, 255, 0.1)",
          }}
        >
          {buttonText}
        </button>
      </div>
      <div className="image-section">
        <img src={image} alt={title} />
      </div>
    </div>
  );
};
