import styled from "styled-components";

export const Title = styled.h1`
  font-size: 50px;
  letter-spacing: 1px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 4px;
    height: auto;
  }

  @media (max-width: 480px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const ServicesContainer = styled.div`
  width: 99%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;

  @media (max-width: 480px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const ServiceCardWrapper = styled.div`
  width: 100%;
`;
export const ServiciosVerticalesWrapper = styled.div`
  width: 100%;
  max-width: 99vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 800px;
`;
