import React, { useCallback, useEffect, useState } from "react";
import { Fade } from "react-reveal";
import { ServicesCard } from "../../components/servicesCard/ServicesCard";
import projectsbG from "../../assests/projects360.webp";
import CtobG from "../../assests/images/services/CTO.webp";
import staffbG from "../../assests/staff-outsourcing.webp";
import { ServiceCardWrapper, ServicesContainer, ServiciosVerticalesWrapper, Title, } from "./ServiciosVerticales.styles";
import { translations } from "../../utils/translations";

const ServiciosVerticales = ({ language }) => {
  const { servicios } = translations[language];

  const [blur, setBlur] = useState(true);
  const handleScroll = useCallback(() => {
    const videoContainer = document.getElementById("video-container");
    if (videoContainer && window.scrollY >= 2) {
      setBlur(false);
    } else {
      setBlur(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <Fade bottom duration={2000} distance="40px" style={{ marginTop: "800px", filter: `${blur ? "blur(4px)" : "none"}` }}>
      <ServiciosVerticalesWrapper id="services">
        <Title style={{ filter: `${blur ? "blur(4px)" : "none"}`, fontWeight:1  }}>
          {servicios.title}
        </Title>
        <ServicesContainer style={{ filter: `${blur ? "blur(4px)" : "none"}`}}>
          <ServiceCardWrapper>
            <ServicesCard
              backImage={projectsbG}
              description={servicios.primer.subHeader}
              benefices={servicios.primer.description}
              id="service-closed-project" // Añadimos el id aquí
              title={servicios.primer.title}
              buttonTxt={servicios.button}
            />
          </ServiceCardWrapper>
          <ServiceCardWrapper>
            <ServicesCard
              backImage={staffbG}
              description={servicios.second.subHeader}
              benefices={servicios.second.description}
              id="service-staff-augmentation" // Añadimos el id aquí
              title={servicios.second.title}
              buttonTxt={servicios.button}
            />
          </ServiceCardWrapper>
          <ServiceCardWrapper>
            <ServicesCard
              backImage={CtobG}
              description={servicios.tercer.subHeader}
              benefices={servicios.tercer.description}
              id="cto-as-a-service" // Añadimos el id aquí
              title={servicios.tercer.title}
              buttonTxt={servicios.button}
            />
          </ServiceCardWrapper>
        </ServicesContainer>
      </ServiciosVerticalesWrapper>
    </Fade>
  );
};

export { ServiciosVerticales };
