export const closedProjectsText = {
  seo: {
    title: "Contratar desarrollo de proyecto cerrado.",
    metaDescription: "",
    keywords:
      "CTO Externo, liderazgo tecnológico, dirección tecnológica, sin costos fijos, Ysolve, desarrollo de software",
    openGraph: {
      title: "",
      description: "",
      image: "",
    },
    breadcrumb: {},
    schema: {},
    url: "https://ysolve.es/cto-externo",
    baseUrl: "https://ysolve.es",
  },
  title: "🚀 ¿Contratar desarrollo de proyectos externos?",
  description: `
  Sabemos lo que es tener una gran idea, pero chocarte con problemas como:
  <div style="padding:10px">
    <p>
      ❌ No saber qué tecnologías usar.
    </p>
    <p>
      ❌ Perder tiempo buscando desarrolladores y coordinando equipos.
    </p>
    <p>
      ❌ Los costos ocultos de infraestructura y mantenimiento.
    </p>
    <p>
      ❌ Retrasos interminables en el desarrollo.
    </p>
  </div>
  
  Con Ysolve, olvídate de <strong>TODO </strong> eso. Te entregamos un proyecto llave en mano: listo para usar, optimizado y sin complicaciones.
    `,
  sections: [
    {
      title: "🔑 ¿Cómo lo hacemos?",
      items: {
        type: "list",
        content: [
          "✅ Desarrollo Full Stack → Webs y apps escalables y de alto rendimiento.",
          "✅ Experiencia impecable para tus usuarios → Interfaces rápidas, atractivas y fáciles de usar.",
          "✅ Backend sólido y seguro → APIs eficientes que hacen que todo fluya sin problemas.",
          "✅ DevOps y automatización → Infraestructura optimizada y sin sorpresas técnicas.",
          "✅ Deploy sin dolores de cabeza → Tu plataforma lista para funcionar sin fallos.",
          "✅ Mantenimiento y soporte → No te dejamos solo; nos aseguramos de que todo siga perfecto.",
        ],
      },
    },
    {
      title: "🌍 Usamos las mejores tecnologías para ti",
      items: {
        type: "list",
        content: [
          "✅ Frontend → React, Vue, Angular, Tailwind, Next.js",
          "✅ Backend → Node.js, Typescrypt, Python",
          "✅ Bases de Datos → PostgreSQL, MySQL, MongoDB, Firebase",
          "✅ DevOps → Docker, Kubernetes, CI/CD, AWS, Azure, GCP",
          "✅ Infraestructura → VPS, servidores dedicados, contenedores y cloud computing",
        ],
      },
    },
    {
      title: "🎯 Este servicio es para ti si...",
      items: {
        type: "list",
        content: [
          "💡 Tienes una startup y necesitas un producto funcional desde el día uno.",
          "🏢 Eres una empresa en crecimiento y quieres escalar sin miedo a problemas técnicos.",
          "📲 Quieres digitalizar tu negocio con una plataforma web o app eficiente.",
          "🛠️ Necesitas software a medida para optimizar tus procesos y ahorrar tiempo.",
        ],
      },
    },
    {
      title:
        "🔥 Tú encárgate de hacer crecer tu negocio. Nosotros nos ocupamos del resto.",
      items: {
        type: "text",
        content: ["📩 Hablemos y pongamos en marcha tu proyecto hoy mismo."],
      },
    },
  ],
};
