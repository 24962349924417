export const menuItems = [
  { label: "Servicios", id: "services" },
  { label: "Proyectos/Portafolio", id: "projects" },
  // { label: "Blog", id: "blog" },
  { label: "Contacto", id: "contact" },
];

export const landingPages = [
  { label: "TeachLead as a service", id: "landing/cto-external" },
  { label: "Closed projects", id: "landing/closed-project" },
  { label: "Staff augmentation", id: "landing/staff-augmentation" },
];
