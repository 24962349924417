import styled from "styled-components";

export const BackButton = styled.div`
  margin: 10px;
  transition: all 0.2s ease-in-out;
  padding: 0.5rem 1rem;
  cursor: pointer;
  box-shadow: 0 2px 10px #dd68b2;
  background-color: #ee2aa6;
  color: #ffffff !important;
  border-radius: 5px;
  width: fit-content;
  text-decoration: none;
  &:hover {
    transform: translateY(-2px);
  }

  &:hover {
    color: white !important;
    text-decoration: none; /* También lo añadimos aquí para asegurar */
  }

  &:active {
    transform: scale(0.95);
  }
`;

export const ServiceContainer = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  margin-top: 4rem;
`;

export const ServiceTitle = styled.h1`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 3rem;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.reverse ? "row-reverse" : "row")};
  align-items: center;
  gap: 2rem;
  margin-bottom: 4rem;
`;

export const TitleContent = styled.div`
  flex: 1;
  h1 {
    padding: 3rem 0;
    text-transform: uppercase;
    padding: 3rem, 0;
    justify-self: center;
    margin-bottom: 1rem;
  }
`;

export const TextContent = styled.div`
  flex: 1;
  h2 {
    margin-bottom: 1rem;
  }
  p {
    line-height: 1.6;
  }
`;

export const ImageContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SectionImage = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;
